<script>
  import Header from "./components/navigation/Header.svelte";
  import Carousel from "./components/Carousel.svelte";
  import LiveEmbed from "./components/LiveEmbed.svelte";
  import Service from "./components/Service.svelte";
  import Input from "./design/components/Input.svelte";
  import Button from "./design/components/Button.svelte";
  import RequestForm from "./components/forms/RequestForm.svelte";
  import Widget from "./components/Widget.svelte";
  import tape from "./data/tape.json";
  import services from "./data/service.json";
  import widgetData from "./data/widget.json";

  //modal
  //request trainig modal
  let trainigModalOpen = false;

  //request Custom work modal
  let customWorkModalOpen = false;

  window.addEventListener("load", () => {
    const tapeScript = document.createElement("script");
    tapeScript.setAttribute(
      "src",
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js"
    );
    tapeScript.setAttribute("type", "text/javascript");
    tapeScript.setAttribute("async", true);
    tapeScript.innerText = `${JSON.stringify({ ...tape })}`;
    document.getElementById("tapeScript").appendChild(tapeScript);
  });
</script>

<main>
  <Header />
  <h2>Beautiful, Accurate & Somewhat Famous Charts</h2>
  <Carousel />
  <section id="learn">
    <h2>Learn About Snarky Charts & BWA</h2>
    <p>
      <strong>Snarky Charts</strong> streams the &quot;<strong
        >Bot Watchers Anonymous</strong
      >&quot; market dashboard live every day on
      <a href="https://rumble.com/SnarkyCharts/live">Rumble</a>. Live account
      trading and technical analysis at random times, recorded and uploaded for
      followers. In-depth market, financial & political analysis and TA tips are
      provided via <a href="https://snarkycharts.substack.com/">Substack</a>.
    </p>
    <p>
      Financial supporters subscribed to Substack receive <strong>free</strong> access
      to their own customizable BWA market dashboard! Literally every single thing
      expect widget placement is customizable; chart/tape symbols & change frequency,
      background images, RSS feeds, Spotify playlist, and more!
    </p>
  </section>
  <section>
    <h2>Watch Our Rumble Live Stream</h2>
    <LiveEmbed />
  </section>
  <section>
    <h2>Join Me on Substack</h2>
    <p>
      Join <a href="https://snarkycharts.substack.com" target="_blank">Snarky Charts 
      on Substack</a> as a free subscriber and you will receive regular updates,
      special live streams and chart analysis.
    </p>
    <a href="https://snarkycharts.substack.com" target="_blank">
      <Button style="margin-top: 1rem">Join Now</Button>
    </a>
  </section>
  <section>
    <h2>Customize Your Own BWA Dashboard!</h2>
    <p>
      Join <a href="https://snarkycharts.substack.com" target="_blank"
        >Snarky Charts on Substack</a
      > as a paying subscriber and you will receive free access to your own customizable
      Bot Watchers Anonymous dashboard kiosk! Not only will you receive regular updates,
      special live streams and chart analysis, but you will also be provided a your
      own login to the Bot Watchers Anonymous dashboard!
    </p>
    <p>
      BWA version 2.0 will allow you to customize literally every single thing
      that you see in the BWA live stream! Custom chart symbols & intervals,
      change or stop chart change interval, change or remove animations &
      background frames, add your own Spotify playlist, change the news feed
      source and more!
    </p>
    <p>
      BWA version 1.0 is officially done.  Version 2.0 is currently in development.  Join
      now as a paying pre-release subscriber for a discounted rate and receive access to
      BWA version 1.0 until version 2.0 is released.  You will also be able to provide
      feedback and feature requests for version 2.0! How awesome is that?
    </p>
    <a href="https://snarkycharts.substack.com" target="_blank">
      <Button style="margin-top: 1rem">Buy Now</Button>
    </a>
  </section>
  <section id="live-charts">
    <h2>Live Interactive Charts</h2>
    <div class="widget-container">
      <Widget id={`area-${widgetData[0].id}`} />
    </div>
    <p>Click inside the chart and type to change tickers.</p>
  </section>
  <section>
    <h2>One-on-One TA Training</h2>
    <p style="text-align: center;">
      Looking to hone your skills with your favorite charting software? <br
      />Request a private session with Snarky Guy himself!
    </p>
    <Button style="margin-top: 1rem" onClick={() => (trainigModalOpen = true)}
      >Request Training Now</Button
    >
  </section>
  <section id="services">
    <h2>Other Snarky Guy Media Services</h2>
    <p>
      Snarky Guy is an absolute machine! He identifies as half-robot (and his
      pronouns are Jesus Can Save You). Visit the links below to find out more!
    </p>
    <div class="services-container">
      {#each services as service (service.title)}
        <Service {...service} />
      {/each}
    </div>
  </section>
  <section>
    <h2>Custom Kiosk Dashboards</h2>
    <p>
      Do you love the real-time dashboard concept so much that you want your own
      with unique widgets, your own domain name and maybe even a completely
      different content niche? Contact us today and let us make your dreams come
      true! We can apply this technology to any field - security cameras,
      cooking, video game streams… you dream it and we can do it!
    </p>
    <Button
      style="margin-top: 1rem"
      onClick={() => (customWorkModalOpen = true)}
      >Request Custom Work Now</Button
    >
  </section>
  <section id="contact">
    <h2>Contact Snarky Guy Directly</h2>
    <div class="contact-wrapper">
      <p>
        Use the following form to send a message directly to SG himself. Please
        note that general commentary and questions should be submitted via
        video/stream/Substack. This form is primarily for interview requests,
        permission to utilize Snarky resources, etc.
      </p>
      <form>
        <Input
          name="name"
          label="Name"
          placeholder="Enter your name"
          id="name"
          type="text"
        />
        <Input
          name="email"
          label="Email"
          placeholder="Enter email"
          id="email"
          type="email"
        />
        <Input
          name="phone"
          label="Phone"
          placeholder="Enter mobile number"
          id="phone"
          type="tel"
        />
        <Input
          name="message"
          label="Message"
          placeholder="Write something"
          id="phone"
          type="textArea"
        />
        <Button style="width: 100%; margin-top: 1rem"
          ><i
            class="fa-solid fa-paper-plane"
            style="margin-right: .5rem"
          />Send</Button
        >
      </form>
    </div>
  </section>
  <RequestForm
    showModal={trainigModalOpen || customWorkModalOpen}
    onClose={() => {
      trainigModalOpen = false;
      customWorkModalOpen = false;
    }}
    title={trainigModalOpen
      ? "Request Training Now"
      : "Request Custom Work Now"}
    id={trainigModalOpen ? "request-training-now" : "request-custom-work-now"}
  />

  <div class="tape-container" id="tapeScript" />
</main>

<style>
  section {
    width: 100%;
    padding: 2rem 1rem;
    margin-top: 1rem;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 20px;
    /* padding: 1rem; */
    margin-bottom: 1rem;
    background: -webkit-linear-gradient(
      left,
      rgba(35, 183, 217),
      rgba(242, 48, 5)
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  section p {
    padding: 1rem;
    font-size: 14px;
    color: #eeeeee;
    line-height: 21px;
  }
  section p a {
    color: #23b7d9;
  }
  .services-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }
  section form {
    width: 100%;
    margin: auto;
  }
  section form div {
    display: flex;
    flex-flow: column;
    align-items: start;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .contact-wrapper {
    display: block;
    margin: 1rem 0rem;
    /* align-items: center; */
  }

  #live-charts {
    display: none;
  }

  #live-charts .widget-container {
    display: block;
  }

  .tape-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 3;
  }

  @media only screen and (min-width: 640px) {
    h2 {
      font-size: 24px;
      padding: 1rem;
    }
    .contact-wrapper {
      display: grid;
      text-align: left;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: 2rem 0rem;
    }
    .services-container {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    #live-charts {
      display: block;
    }
  }
</style>
