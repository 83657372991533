<script>
  const items = [
    "/assets/images/carousel/carousel-1.png",
    "/assets/images/carousel/carousel-2.png",
    "/assets/images/carousel/carousel-3.png",
    "/assets/images/carousel/carousel-4.png",
    "/assets/images/carousel/carousel-5.png",
    "/assets/images/carousel/carousel-6.png",
    "/assets/images/carousel/carousel-7.png",
    "/assets/images/carousel/carousel-8.png",
  ];
  let currentIndex = 0;
  const next = () => {
    if (items.length - 1 === currentIndex) {
      currentIndex = 0;
    } else {
      ++currentIndex;
    }
    console.log(currentIndex);
  };
  const prev = () => {
    if (currentIndex > 0) {
      --currentIndex;
    } else {
      currentIndex = items.length - 1;
    }
    console.log(currentIndex);
  };
  const carouselInterval = setInterval(() => {
    next();
  }, 5000);
</script>

<div class="carousel">
  <div class="carousel-item">
    <img src={items[currentIndex]} alt="" height="100%" />
  </div>
  <button on:click={prev} class="carousel-button carousel-button-prev">
    <i class="fa-solid fa-chevron-left" />
  </button>
  <button on:click={next} class="carousel-button carousel-button-next">
    <i class="fa-solid fa-chevron-right" />
  </button>
</div>

<style>
  .carousel {
    width: 100%;
    height: 10rem;
    position: relative;
    z-index: 0;
    margin-top: 2rem;
  }
  .carousel-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .carousel-button {
    position: absolute;
    z-index: 1;
    top: calc(50% - (24px / 2));
    cursor: pointer;
    font-size: 24px;
    background-color: transparent;
    color: #ffffff;
    outline: none;
    border: none;
  }
  .carousel-button-prev {
    left: 1rem;
  }
  .carousel-button-next {
    right: 1rem;
  }

  @media only screen and (min-width: 640px) {
    .carousel {
      height: 30rem;
      margin-top: 2rem;
    }
  }
</style>
