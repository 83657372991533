<script>
  import { onMount } from "svelte";
  import Button from "../../design/components/Button.svelte";
  import Nav from "./Nav.svelte";
  import Sidebar from "./Sidebar.svelte";

  let openSidebar = false;
  $: openSubscription = false;

  onMount(() => {
    if (openSubscription) {
      window.addEventListener("click", () => {
        openSubscription = false;
      });
    }
  });
</script>

<header>
  <div class="logo">
    <img
      src="/assets/images/logo.png"
      alt="snarky charts"
      width="100%"
      style="transform: translateY(-22%)"
    />
  </div>
  <div class="navigation">
    <nav>
      <Nav href="#live">Live</Nav>
      <Nav href="#learn">Learn</Nav>
      <Nav href="#services">Services</Nav>
      <Nav
        onClick={() => {
          openSubscription = true;
        }}>Subscribe</Nav
      >
      <div
        class="subscription"
        style="opacity: {openSubscription
          ? 1
          : 0}; pointer-events: {openSubscription ? 'auto' : 'none'}"
      >
        <div
          class="card"
          on:click={() => {
            location.replace("https://buy.stripe.com/test_aEU8zG1zW9xE77ieUU");
          }}
        >
          <div>
            <h3>Monthly</h3>
            <h4>$10.00</h4>
          </div>
          <p>With best features</p>
        </div>
        <div
          class="card"
          on:click={() => {
            location.replace("https://buy.stripe.com/test_eVa9DK7Yk39ggHS6op");
          }}
        >
          <div>
            <h3>Yearly</h3>
            <h4>$100.00</h4>
          </div>
          <p>With best features</p>
        </div>
      </div>
    </nav>
    <a href="#contact" class="contact">
      <Button>
        <span class="backdrop" />
        <span style="z-index: 2; position: relative">Contact</span></Button
      >
    </a>
  </div>
  <button
    on:click={() => {
      openSidebar = true;
    }}
  >
    <span />
    <span />
    <span />
  </button>
</header>
<div
  style=" opacity: 0.7; position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; display: {openSubscription
    ? 'block'
    : 'none'}; z-index: 5"
  on:click={() => {
    openSubscription = false;
  }}
  on:keypress={(e) => {}}
/>
{#if openSidebar}
  <Sidebar onClose={() => (openSidebar = false)} />
{/if}

<style>
  header {
    width: 100%;
    padding: 0.25rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .logo {
    width: 9rem;
    height: 5rem;
    overflow: hidden;
  }

  .navigation {
    display: none;
    align-items: center;
    gap: 2.5rem;
    padding-right: 2rem;
  }
  nav {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    position: relative;
  }
  .backdrop {
    width: calc(100% - 0.25rem);
    height: calc(100% - 0.25rem);
    background-color: #000;
    position: absolute;
    top: calc(0.25rem / 2);
    right: calc(0.25rem / 2);
    transform-origin: center;
    border-radius: 999px;
    z-index: 1;
    transition: all 150ms ease-in-out;
  }

  button {
    width: 50px;
    height: 50px;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    background-color: transparent;
    border: none;
    outline: none;
    gap: 0.5rem;
  }

  button span {
    height: 0.1rem;
    width: calc(100% - 0.5rem);
    background-color: #fff;
  }

  .subscription {
    position: absolute;
    bottom: -7rem;
    right: 0;
    border-radius: 8px;
    overflow: hidden;
    color: #000;
    z-index: 7;
  }

  .subscription .card {
    width: 200px;
    padding: 0.5rem;
    background-color: #fff;
    cursor: pointer;
    transition: all 150ms ease;
  }

  .subscription .card:hover {
    background-color: #eeeeee;
  }

  .subscription .card div {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }

  .subscription .card div h3 {
    font-size: 16px;
  }

  .subscription .card div h4 {
    font-size: 14px;
  }

  .subscription .card p {
    font-size: 12px;
  }

  @media only screen and (min-width: 640px) {
    .navigation {
      display: flex;
    }
    button {
      display: none;
    }
  }
</style>
