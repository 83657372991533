<script>
  export let id;
  // export let symbol;
  const widgetId = `widget-${id}`;
  console.log(widgetId);
  window.addEventListener("load", () => {
    new TradingView.widget({
      autosize: true,
      interval: "D",
      timezone: "America/New_York",
      theme: "dark",
      style: "1",
      locale: "en",
      toolbar_bg: "#000",
      allow_symbol_change: true,
      hide_legend: false,
      hide_side_toolbar: false,
      hide_top_toolbar: false,
      save_image: true,
      show_popup_button: true,
      withdateranges: true,
      backgroundColor: "rgba(0, 0, 0, 1)",
      symbol: "COINBASE:BTCUSD",
      container_id: widgetId,
      outerWidth: 720,
      outerHeight: 360,
    });
  });

  console.log(widgetId);
</script>

<div id={widgetId} />

<style>
  div {
    width: 100%;
    height: 480px;
    min-height: 260px;
  }
</style>
