<script>
  import Button from "../../design/components/Button.svelte";
  import Nav from "./Nav.svelte";
  export let onClose;
</script>

<section>
  <nav>
    <Nav href="#live">Live</Nav>
    <Nav href="#learn">Learn</Nav>
    <Nav href="#services">Services</Nav>
  </nav>
  <a href="#contact" class="contact">
    <Button>
      <span class="backdrop" />
      <span style="z-index: 2; position: relative">Contact</span></Button
    >
  </a>
  <button on:click={() => onClose()}>
    <i class="fa-solid fa-xmark" />
  </button>
</section>

<style>
  section {
    max-width: 300px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #000000;
    z-index: 10;
    padding: 5rem 2.5rem;
  }
  section::backdrop {
    background: #eeeeee20;
  }
  nav {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .contact {
    margin-top: 2rem;
  }
  button {
    background-color: transparent;
    outline: none;
    border: none;
    color: #ffffff;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  @media only screen and (min-width: 640px) {
    section {
      display: none;
    }
  }
</style>
