<script>
  export let href;
  export let onClick;
</script>

<a {href} on:click={onClick}>
  <slot />
</a>

<style>
  a {
    text-decoration: none;
    color: #ffffff;
    transition: all 150ms ease;
    font-size: 14px;
    position: relative;
    width: max-content;
    overflow: hidden;
  }
  a::before {
    content: "";
    width: 100%;
    height: 0.1rem;
    border-radius: 999px;
    background: linear-gradient(
      90deg,
      rgba(242, 48, 5, 1) 0%,
      rgba(35, 183, 217, 1) 61%
    );
    position: absolute;
    bottom: 0;
    left: -100%;
    transition: all 150ms ease-in-out;
  }
  a:hover {
    color: #23b7d9;
  }

  a:hover::before {
    left: 0;
  }
</style>
