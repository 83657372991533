<script>
</script>

<iframe
  src=" https://rumble.com/embed/v2zqp94/?pub=1reg9m&autoplay=2"
  width="640"
  height="360"
  frameborder="0"
  title=""
/>

<style>
  iframe {
    max-width: 100%;
  }

  @media only screen and (min-width: 640px) {
    iframe {
      max-width: none;
    }
  }
</style>
