<script>
  import Button from "../../design/components/Button.svelte";
  import Input from "../../design/components/Input.svelte";
  import Modal from "../../design/components/Modal.svelte";

  export let showModal;
  export let title;
  export let id;

  console.log("showModal", showModal);
</script>

<Modal bind:showModal>
  <h3 slot="header">{title}</h3>
  <form>
    <Input
      name="name"
      label="Name"
      placeholder="Enter your name"
      id={`name-${id}`}
      type="text"
    />
    <Input
      name="email"
      label="Email"
      placeholder="Enter email"
      id={`email-${id}`}
      type="email"
    />
    <Input
      name="phone"
      label="Phone"
      placeholder="Enter mobile number"
      id={`phone-${id}`}
      type="tel"
    />
    <div class="preferred">
      <label for="">Preferred Contact Method</label>
      <div class="radio-group">
        <div class="radio-container">
          <input
            type="radio"
            name="preferred-contact-method"
            id={`preferred-email-${id}`}
            value="email"
          />
          <label for={`preferred-email-${id}`}>Email</label>
        </div>
        <div class="radio-container">
          <input
            type="radio"
            name="preferred-contact-method"
            id={`preferred-phone-${id}`}
            value="phone"
          />
          <label for={`preferred-phone-${id}`}>Phone</label>
        </div>
      </div>
    </div>
    <Button style="width: 100%; margin-top: 1rem">
      <i class="fa-solid fa-paper-plane" style="margin-right: .5rem" />
      Send
    </Button>
  </form>
</Modal>

<style>
  h3 {
    text-align: center;
    color: #ffffff;
  }
  .preferred {
    color: white;
  }
  .preferred label {
    font-size: 14px;
    color: #eeeeee;
    line-height: 21px;
    margin-bottom: 0.25rem;
  }

  .preferred .radio-group {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .preferred .radio-group .radio-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
</style>
