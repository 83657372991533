<script>
  export let title;
  export let description;
  export let icon;
  export let link;
</script>

<a href={link} target="_blank">
  <div class="service-container">
    <div class="backdrop" />
    <i class={icon} />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
</a>

<style>
  a {
    text-decoration: none;
    color: inherit;
  }
  .service-container {
    background: linear-gradient(
      90deg,
      rgba(242, 48, 5, 1) 0%,
      rgba(35, 183, 217, 1) 61%
    );
    width: 100%;
    height: 198px;
    border-radius: 8px;
    padding: 1rem;
    overflow: hidden;
    transition: all 150ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 0;
    cursor: pointer;
  }
  .service-container h3 {
    z-index: 2;
  }
  .service-container i {
    transition: all 150ms ease-in-out;
    font-size: 32px;
    margin-bottom: 1rem;
    height: max-content;
    transform: translateY(0px);
    z-index: 2;
    display: none;
  }
  .service-container p {
    font-size: 14px;
    /* color: #eeeeee; */
    line-height: 21px;
    margin-top: 1rem;
    transition: all 150ms ease-in-out;
    height: max-content;
    transform: translateY(0px);
    z-index: 2;
  }

  .service-container:hover i {
    height: 0;
    transform: translateY(calc(-198px / 2));
  }
  .service-container:hover p {
    height: max-content;
    transform: translateY(0px);
  }
  .backdrop {
    position: absolute;
    width: calc(100% - 0.5rem);
    height: calc(100% - 0.5rem);
    background: #000;
    transition: all 150ms ease-in-out;
    border-radius: 6px;
    top: 0.25rem;
    z-index: 1;
    display: none;
  }
  .service-container:hover .backdrop {
    height: 0;
  }

  @media only screen and (min-width: 640px) {
    .backdrop {
      display: inline;
    }
    .service-container i {
      display: block;
    }
    .service-container p {
      transform: translateY(calc(198px / 2));
      height: 0;
    }
  }
</style>
