<script>
  export let onClick;
  export let style;
</script>

<button on:click={() => onClick()} {style}>
  <slot />
</button>

<style>
  button {
    background: linear-gradient(
      90deg,
      rgba(242, 48, 5, 1) 0%,
      rgba(35, 183, 217, 1) 61%
    );
    border: none;
    outline: none;
    padding: 0.5rem 1.2rem;
    border-radius: 999px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    z-index: 0;
    overflow: hidden;
  }
</style>
