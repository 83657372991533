<script>
  export let name;
  export let label;
  export let placeholder;
  export let id;
  export let type;
</script>

<div>
  <label for={id}>{label}</label>
  {#if type === "textArea"}
    <textarea {name} {placeholder} {id} />
  {:else}
    <input {name} {placeholder} {id} {type} />
  {/if}
</div>

<style>
  div {
    display: flex;
    flex-flow: column;
    align-items: start;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  div label {
    font-size: 14px;
    color: #eeeeee;
    line-height: 21px;
    margin-bottom: 0.25rem;
  }
  div input,
  div textarea {
    background-color: transparent;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    transition: all 300ms ease;
    font-size: 14px;
    padding: 0.75rem;
    width: 100%;
  }
  div input:hover,
  div textarea:hover {
    border-color: #ffffff;
  }
</style>
